// container
.container {

	@include media-breakpoint-down(sm) {
		padding-left: 30px;
		padding-right: 30px;
	}

	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: .3s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border-radius: 10px;

	.card-image {
		.card-image-caption {
			display: flex !important;
			position: absolute;
			top: 10px;
			right: 10px;
			left: auto;
			bottom: auto;
			width: 100px;
			height: 100px;
			border-radius: 50%;
			background-color: $pink-light;
			align-items: center;
			justify-content: center;

			.card-image-label {
				color: $pink;
				font-family: $headings-font-family;
				font-size: 16px;
				text-overflow: unset;
				white-space: unset;
				text-align: center;
			}
		}
	}
}

.card.card-overlay {
	border-radius: .5rem;
	border: none;

	.card-img-overlay {
		padding: 1.25rem;
		display: flex;
		align-items: flex-end;

		p {
			margin-bottom: 0;
		}
	}

	.card-caption {
		text-shadow: none;

		.card-description {
			a {
				margin-right: .5rem;
			}
		}
	}

	.card-image {
		height: 100%;


		.card-img {
			height: 100%;
			width: auto;
			object-fit: cover;
		}

		.card-image-caption {
			display: flex !important;
			position: absolute;
			top: 20px;
			right: 20px;
			left: auto;
			bottom: auto;
			width: 124px;
			height: 124px;
			border-radius: 50%;
			background-color: $pink-light;
			align-items: center;
			justify-content: center;

			.card-image-label {
				color: $pink;
				font-family: $headings-font-family;
				font-size: 16px;
				text-overflow: unset;
				white-space: unset;
				text-align: center;
			}
		}
	}
}
