// mini-sab
.mini-sab {
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-start;

		.container-holder {
			max-width: 350px;
			z-index: 100;
			position: absolute;
			padding: 3rem 2rem 1.5rem;
			border-radius: 8px;
			background: $secondary;
			box-shadow: 3px 3px 6px rgba($black, 0.15);
			color: $primary;
			bottom: -3rem;

			@include media-breakpoint-down(sm) {
				margin-left: auto;
				margin-right: auto;
			}
		}

		.title,
		.wysiwyg {
			margin-bottom: 1rem;
		}
	}

	.vocabulary-term-list.top {
		ul {
			li {
				@extend .title, h4, .text-center;
			}
		}
	}
	.vocabulary-term-list.bottom {
		ul {
			li {
				@extend .text-center;
			}
		}
	}
}

// hide mini-sab
&.accommodation-category-overview,
&.accommodation-category-detail,
&.accommodation-overview,
&.accommodation-search-book,
&.accommodation-book,
&.accommodation-book-error,
&.accommodation-book-succes,
&.accommodation-book-terms-and-conditions,
&.accommodation-detail {
	.mini-sab {
		display: none;
	}
}
