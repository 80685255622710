.collection {
    &.usps {
        margin-top: 2.5rem;

        .grid-items {
            column-gap: 2rem;
            .item {
                @include media-breakpoint-up(md) {
                    flex: 0 0 auto;
                    max-width: max-content;
                }
            }
        }

        .card.icon {
            flex-direction: row;
            align-items: center;
            border: none;

            .card-image {
                font-size: 2rem;
                color: $special-light;
            }

            .card-body {
                padding: 0 .5rem;
            }

            .card-buttons {
                display: none;
            }

            .card-caption {
                .card-title {
                    font-family: $font-family-base;
                    font-weight: 700;
                    font-size: $font-size-base;
                    margin: 0;
                    color: $special;
                }
            }
        }
    }

    &.grid.masnory {
        @include media-breakpoint-up(lg) {
            margin-top: -5rem;
        }

        .grid-items {
            align-items: flex-end;
            margin-bottom: -1rem;
            .item {
                margin-bottom: 1rem;
                padding-left: .5rem;
                padding-right: .5rem;

                &:nth-child(1) {
                    @include media-breakpoint-up(lg) {
                        @include make-col(7);
                    }
                }
                &:nth-child(2) {
                    @include media-breakpoint-up(lg) {
                        @include make-col(5);
                    }
                }
                &:nth-child(3) {
                    @include media-breakpoint-up(lg) {
                        @include make-col(5);
                    }

                    .card {
                        aspect-ratio: 3 / 2.3;
                    }
                }
                &:nth-child(4) {
                    align-self: flex-start;
                    @include media-breakpoint-up(lg) {
                        @include make-col(5);
                    }
                }

            }
        }
    }

    &.custom-masnory {
        .card-columns {
            @include media-breakpoint-up(md) {
                column-count: 2;
                column-gap: 1.5rem;

                .item {
                    float: left;
                    // width: 50%;
                }
            }

            .card {
                aspect-ratio: 4/3;
                margin-bottom: 1rem;
            }

            .card.transparent {
                aspect-ratio: unset;

                .card-image {
                    display: none;
                }

                .card-img-overlay {
                    padding: 0;
                    background: none;
                    border: none;
                    position: static;

                    .card-title {
                        color: $primary;
                    }

                    .card-caption {
                        color: $black;

                        .card-description {
                            p {
                                margin-bottom: 1rem;
                            }

                            ul {
                                margin-bottom: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}