// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 3.5rem 0;
	}

	&.bg-light {
		@include headings-style {
			color: $primary;
		}
	}
}

// .lead-section+.content-section,
// .lead-section+.bundle-overview-section {
// 	margin-top: calc(-6vh + 30px);
// }

// lead-section
// =========================================================================
.lead-section {
	padding: 3.5rem 0;

	.container-one-column {
		.container-holder {
			max-width: 780px;
			margin: 0 auto;
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	// margin-top: 1.5rem !important;
	padding: 3.5rem 0;

	.container-two-columns.intro {
		.container-holder {
			> .column {
				&.one {
					@include media-breakpoint-up(md) {
						@include make-col(8);
					}

					.wysiwyg {
						p {
							max-width: 72%;
							margin-top: 1rem;
							margin-left: auto;
							margin-right: auto;
						}
					}
				}
				&.two {
					.resource-image {
						text-align: center;
						img {
							max-width: 200px;
						}
					}

					@include media-breakpoint-up(md) {
						@include make-col(4);

						.resource-image {
							margin-top: -6rem;

							img {
								max-width: 100%;
							}
						}
					}
				}
			}
		}
	}

	.outro {
		p {
			max-width: 500px;
		}
		.btn {
			margin-right: 10px;
		}
	}
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;
}

// banner-section
// =========================================================================
.banner-section {
	overflow: hidden;

	&.eyecatcher.large,
	&.eyecatcher.large .owl-carousel .item {
		@include media-breakpoint-up(lg) {
			height: 100vh;
			max-height: 740px;
		}
	}

	&.eyecatcher.large .owl-carousel {
		.item {
			.owl-caption-holder {
				height: 100%;
				padding: 1rem;
				display: flex;
				align-items: flex-end;

				.container {
					max-width: none;
				}
			}

			.owl-caption {
				transform: unset;
				text-align: left;
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}

// faq-section
// =========================================================================
.faq-section {
	padding-top: 0;
	a {
		display: block;
		color: $primary;
		font-family: $font-family-base;
		font-weight: 700;
		margin-top: 1.5rem;
	}
}
