// btn
.btn {
	// box-shadow: $shadow;
	font-weight: 700;
	text-transform: uppercase;
	padding: 10px 1.5rem;
	line-height: normal;
	border-radius: 99px;
	font-size: 14px;

	// btn-primary
	// &.btn-primary {
	// 	&:hover {}
	// }

	// btn-outline-primary
	// &.btn-outline-primary {
	// 	&:hover {}
	// }

	// btn-secondary
	// &.btn-secondary {
	// 	&:hover {}
	// }

	// btn-outline-secondary
	// &.btn-outline-secondary {
	// 	&:hover {}
	// }
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}

// zoek en boek
ul.book-button {
	a {
		@extend .btn;
		@extend .mt-md-3;
		@extend .ml-3;
		@extend .ml-md-0;
		@extend .text-white;

		background-color: $special;
		border: 1px solid $special;

		&:hover {
			background-color: darken($special, 50%);
			border: 1px solid darken($special, 50%);
		}

		@include media-breakpoint-down(md) {
			font-size: 12px;
		}
	}
}