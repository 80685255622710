// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$green: #B8D1BE;
$green-dark: #275C64;
$white: #fff;
$black: #000;
$pink: #E561CD;
$pink-light: #E0CFE0;
$yellow-light: #FEF8F2;

// theme-colors
$primary: $green-dark;
$secondary: $green;
$special: $green-dark;
$special-light: $green;

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $special;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
// @import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Inter:opsz,wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:opsz,wght@14..32,100..900&display=swap');
$font-family-primary: "Inter", sans-serif;
$font-family-secondary: "Bree Serif", serif;

$font-family-base: $font-family-primary;

$font-size-base: .938rem; // 15px

$h1-font-size: 16px * 3; // 48px
$h2-font-size: 16px * 2.25; // 36px
$h3-font-size: 16px * 2; // 32px
$h4-font-size: 16px * 1.5; // 24px
$h5-font-size: 16px * 1.125; // 18px
$h6-font-size: 16px; // 16px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 400;
$headings-color: $primary;

// contextual
$light: $yellow-light;
$dark: $green-dark;
$text-light: $white;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
