.faq-overview {
	margin-top: 1rem;
	> .faq-category {
		margin: 0;
		border-top: 1px solid $secondary;
		
		&:last-child {
			border-bottom: 1px solid $secondary;
		}
	}

	.faq-category {
		margin: 0;

		.faq-title {
			padding: 1rem 50px 1rem 0;

			h2 {
				color: $primary;
				font-family: $font-family-base;
				font-weight: 700;
				font-size: $h6-font-size;
			}

			.faq-icon {
				.fa-chevron-up::before {
					content: "\f068";
				}
			}

			&:hover {
				h2,
				.faq-icon {
					color: $primary;
				}
			}
		}
		.faq-items {
			.faq-item {
				&.active {
					.faq-icon {
						.fa::before {
							content: "\f068";
						}
					}
				}

				&.active,
				&:hover {
					.faq-question {
						h3 {
							color: $primary;
						}
					}

					.faq-icon {
						color: $primary;
					}
				}

				&:not(:last-child) {
					border-bottom: 1px solid $secondary;
				}

				.faq-question {
					border: none;
					padding: 1rem 50px 1rem 0;
					margin: 0;

					a {
						display: none;
					}

					h3 {
						color: $primary;
						font-size: $font-size-base;
						font-weight: 700;
                        transition: .2s ease;
						font-family: $font-family-base;
					}

					&:hover {
						.faq-icon {
							color: $primary;
						}
					}
				}

				.faq-answer {
					padding: 0;
					border: none;
					margin: 0;

					a {
						color: $primary;
						&:hover {
							color: $primary;
						}
					}
				}
			}
		}
	}

	.faq-icon {
		transition: .2s ease;
		right: 0;
		left: unset;
		font-size: 14px;
		height: 100%;
		display: flex !important;
		justify-content: flex-end;
		align-items: center;
		color: $secondary;

		.fa::before {
			content: "\f067";
		}
	}
}

// faq page
&.faq-overview {
	.faq-overview {
		width: 100%;
		max-width: 720px;
		margin-left: auto;
		margin-right: auto;
	}
}