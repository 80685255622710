.gallery,
.gallery-horizontal {
    .gallery-item {
        .gallery-link {
            border-radius: 10px;
            overflow: hidden;
        }

        .gallery-label {
            display: flex !important;
			position: absolute;
			top: 10px;
			right: 10px;
			left: auto;
			bottom: auto;
			width: 100px;
			height: 100px;
			border-radius: 50%;
			background-color: $special-light;
			align-items: center;
			justify-content: center;
            color: $special;
            font-family: $headings-font-family;
            font-size: 16px;
        }
    }
}