&.notice-cookie-consent-general-terms,
&.disclaimer,
&.privacy-statement {

    .lead-section {
        @extend .bg-light;

        .container-one-column {
            .container-holder {
                // text-align: left;
            
                .page-title {
                    text-align: center;
                }
            }
        }
    }

    .content-section {

        .container-one-column {
            .container-holder {
                max-width: 750px;
                margin: 0 auto;
            }
        }
    }
}