// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: 0.5s;
	padding: 14px 0;

	&.sticky {
		box-shadow: $shadow;
	}

	.container,
	.container-fluid {
		@include media-breakpoint-up(lg) {
			padding-left: 2rem;
			padding-right: 2rem;
		}
		.container-holder {
			>.column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-md;
			}
		}
	}

	.container-three-columns {
		.container-holder {
			>.column {
				&.one {
					@include make-col(3);

					@include media-breakpoint-up(md) {
						@include make-col(4);
					}
				}
				&.two {
					@include make-col(12);

					@include media-breakpoint-down(sm) {
						order: 1
					}

					@include media-breakpoint-up(md) {
						@include make-col(4);
					}
				}
				&.three {
					@include make-col(9);

					@include media-breakpoint-up(md) {
						@include make-col(4);
					}
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			color: $special;

			.navbar-toggler-icon {
				text-align: center;

				.fa-times {
					display: none;
				}
			}
		}

		&.clicked {
			.navbar-toggler {
				.navbar-toggler-icon {
					.fa-times {
						display: block;
					}
					.fa-bars {
						display: none;
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 240px;

		@include media-breakpoint-up(md) {
			width: unset;
		}
		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		.navbar-nav {
			margin-top: 10px;

			.nav-item {
				.nav-link {
					color: $black;
					font-family: $font-family-secondary;
					font-weight: 400;
					font-size: $h6-font-size;

					&:hover {
						color: $primary;
					}
				}

				&.active {
					.nav-link {
						color: $primary;
					}
				}
			}
		}

		@include media-breakpoint-down(lg) {
			padding-left: 0.75rem;
			
			.dropdown-menu {
				.nav-link {
					padding-left: 1rem;
					padding-right: 1rem;
				}
			}
		}

		@include media-breakpoint-up(xl) {
		}
	}

	// site-switcher
	// .site-switcher {}
}
