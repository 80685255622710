// footer
.footer {
	background-color: $secondary;
	padding: 2rem 0 0;

	.footer-outro {
		padding-bottom: 3rem;
	}

	.footer-sitelinks {
		padding-top: 3rem;
	}

	// .container-four-columns {
	//     .container-holder {
	// 		>.column {
	//             @include media-breakpoint-down(sm) {
	//                 @include make-col(6);
	//             }
	//         }
	//     }
	// }

	.footer-logolink {
		.list {
			justify-content: center;
			align-items: center;
			gap: 1rem;

			&-item {
				&:first-child {
					@include media-breakpoint-down(sm) {
						order: 1;
					}
				}
			}
		}
	}

	.footer-text {
		@include media-breakpoint-down(md) {
			padding-left: 2rem;
		}

		h6 {
			margin-bottom: 14px;

			i {
				width: 16px;
				display: inline-block;
				color: $white;
				margin-left: -1.5rem;
				margin-right: 5px;
			}
		}

		p {
			margin-bottom: 0;
			color: $primary;
		}
	}

	.social-media {
		column-gap: 10px;

		.social-media {
			@include media-breakpoint-down(md) {
				padding-left: 2rem;
			}
		}

		a {
			color: $primary;

			&:hover {
				color: $special;
			}
		}

		.list-item-title {
			display: none;
		}
	}
	.footer-socket {
		background: $green-dark;
		margin-top: 3vh;
		padding: 1rem 0;
		.list {
			display: flex;
			gap: 0.35rem;
			li {
				display: flex;
				align-items: center;
				color: $secondary;
				a {
					color: $secondary;
				}
				&::after {
					content: "|";
					margin-left: 0.35rem;
				}
				&:last-child() {
					&::after {
						display: none;
					}
				}
			}
			@include media-breakpoint-down(md) {
				flex-flow: column;
				li {
					&::after {
						display: none;
					}
				}
			}
		}
	}
}
